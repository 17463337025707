<template>
  <div>
    <b-row>
      <b-col md="12">
        <a
          @click="$router.back()"
          href="#"
          class="
            text-dark-75
            font-weight-bolder
            text-hover-primary
            mb-1
            font-size-lg
            d-flex
            flex-row
            align-items-center
          "
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom gutter-b" v-if="currentUjian">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex flex-column mr-3">
                    <a
                      href="#"
                      class="
                        text-dark-75 text-hover-primary
                        font-size-h5 font-weight-bold
                        mr-3
                      "
                    >
                      {{ currentStudentResultExam.student_id.full_name }}
                    </a>
                    <a
                      href="#"
                      class="
                        text-dark-50 text-hover-primary
                        font-size-h7 font-weight-bold
                        mr-3
                      "
                    >
                      {{ currentUjian.name }}
                    </a>
                  </div>

                  <div class="my-lg-0 my-3">
                    <button
                      v-b-toggle.deskripsi
                      class="
                        btn btn-light-info
                        font-weight-bolder font-size-sm
                        mr-2
                      "
                    >
                      Lihat Deskripsi
                    </button>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a
                        href="#"
                        class="
                          text-dark-50 text-hover-primary
                          font-weight-bold
                          mr-lg-8 mr-5
                          mb-lg-0 mb-2
                        "
                      >
                        <i class="flaticon-clock-1 mr-2 font-size-lg"></i>
                        {{ currentUjian.time }} Menit
                      </a>
                      <a
                        href="#"
                        class="
                          text-dark-50 text-hover-primary
                          font-weight-bold
                          mr-lg-8 mr-5
                          mb-lg-0 mb-2
                        "
                        ><i class="flaticon2-calendar-2 mr-2 font-size-lg"></i>
                        {{
                          currentUjian.opening_time | moment("dddd, LL HH:mm")
                        }}
                        -
                        {{
                          currentUjian.closing_time | moment("dddd, LL HH:mm")
                        }}
                      </a>
                    </div>

                    <b-collapse id="deskripsi" class="mt-2">
                      <b-card>
                        <div v-html="currentUjian.description"></div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->

            <div class="separator separator-solid"></div>

            <!--begin::Items-->
            <div class="d-flex align-items-center flex-wrap mt-8">
              <div class="d-flex align-items-center mr-5 mb-2">
                <span class="mr-4">
                  <i
                    class="
                      flaticon-file-2
                      display-4
                      text-muted
                      font-weight-bold
                    "
                  ></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm"
                    >Nilai ujian</span
                  >
                  <span
                    class="
                      label label-lg label-inline
                      font-weight-bold
                      py-4
                      label-light-warning
                      mr-1
                    "
                  >
                    {{ currentStudentResultExam.scores }} pts
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center mb-2 float-left">
                <span class="mr-4">
                  <i
                    class="
                      flaticon-network
                      display-4
                      text-muted
                      font-weight-bold
                    "
                  ></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Kelas</span>
                  <div>
                    <!-- <span
                      class="
                        label label-lg label-inline
                        font-weight-bold
                        py-4
                        label-light-info
                        mr-1
                      "
                      v-for="kelas in currentUjian.class_id"
                      :key="kelas"
                    >
                      {{ kelas }} 
                    </span> -->
                    <span
                      class="
                        label label-lg label-inline
                        font-weight-bold
                        py-4
                        label-light-info
                        mr-1
                      "
                    >
                      {{ currentUjian.class_id.length }} Kelas
                    </span>
                  </div>
                </div>
              </div>
              <!--end::Item-->
            </div>
            <!--begin::Items-->
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Jawaban Siswa
              </span>
              <!--              <b-skeleton width="100%" v-if="isLoading"></b-skeleton>-->
              <!--              <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="!isLoading && currentSoalList[0]">{{ currentSoalList[0].ujian.nama }}</span>-->
            </h3>
            <div class="card-toolbar">
              <span
                @click="storeSoal"
                class="btn btn-success font-weight-bolder font-size-sm mr-2"
              >
                Simpan</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <ul
              class="nav nav-dark nav-bold nav-tabs nav-tabs-line mb-4"
              role="tablist"
              ref="builder-tab"
            >
              <!-- <li
                class="nav-item"
                v-for="(kelas, i) in currentSoalEssay.kurikulum.one_ajar.jadwal"
                :key="`kelas${kelas.id_kelas}${i}`"
              >
                <a
                  :class="`nav-link ${i == 0 ? 'active' : ''}`"
                  v-on:click="(event) => setActiveTab(event)"
                  :data-tab="i"
                  data-toggle="tab"
                  href="#"
                  role="tab"
                >
                  {{ kelas.kelas.nama_kelas }}
                </a>
              </li> -->
            </ul>
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              :items="currentStudentResultExam.answers"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <b-row class="justify-content-start">
                  <b-col>
                    <div
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >
                      Soal:
                    </div>
                    <div v-html="row.item.question"></div>
                  </b-col>
                  <b-col>
                    <div
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >
                      jawaban siswa:
                    </div>
                    <p v-if="row.item.type == `essay`">
                      {{ row.item.student_answer }}
                    </p>

                    <p
                      v-else
                      v-for="answer in row.item.student_answer"
                      :key="answer"
                    >
                      {{ answer.answer }}
                    </p>
                  </b-col>
                </b-row>

                <!-- <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg"> -->
                <!-- Jawaban: -->
                <!-- </div> -->
                <!-- <div
                  v-html="
                    row.item.siswa.one_nilai_ujian.one_jawaban_siswa.jawaban
                  "
                  v-if="row.item.siswa.one_nilai_ujian.one_jawaban_siswa"
                ></div> -->
              </template>

              <template #cell(soal)="data">
                <!-- <div
                  v-html="data.item.soal"
                  style="
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                ></div> -->
              </template>
              <template #cell(question_code)="data">
                {{ currentUjian.question_pack_id.questions.filter((item) => item._id == data.item.question_id)[0].question_code }}
              </template>
              <template #cell(progress)="data">
                <div class="d-flex align-items-center">
                  <div class="progress progress-xs mx-3 w-100">
                    <!-- <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      :style="`width: ${getProgress(data.item)}%;`"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div> -->
                  </div>
                  <!-- <span class="font-weight-bolder text-dark"
                    >{{ getProgress(data.item) }}%</span
                  > -->
                </div>
              </template>
              <template #cell(tipe)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  <!-- {{ tipeSoal[data.item.tipe] }} -->
                </span>
              </template>
              <template #cell(siswa)="data">
                <b-row class="align-items-center">
                  <b-col cols="auto">
                    <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                      <img
                        :src="`https://eschool.smkn4bdg.sch.id/img.php?src=http://img.smkn4bdg.sch.id/siswa/${data.item.siswa.nis}.jpg`"
                        class="symbol-label"
                        alt=""
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <!-- <a
                      class="
                        text-dark
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >{{ data.item.siswa.siswa.nama_lengkap }}</a
                    > -->
                    <!-- <span class="text-muted font-weight-bold d-block">{{
                      data.item.siswa.nis
                    }}</span> -->
                  </b-col>
                </b-row>
              </template>
              <template #cell(bobot)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  <!-- {{ data.item.bobot }} pts -->
                </span>
              </template>
              <template #cell(topik)="data"> </template>
              <template #cell(nilai)="data">
                <!-- <span class="font-weight-bolder text-dark">{{
                  data.item.student_answer.filter((item) => item.isCorrect)
                    .length
                }}</span> -->
                
                <b-input-group v-if="data.item.student_answer" :append="`0-${currentUjian.question_pack_id.questions.filter((item) => item._id == data.item.question_id)[0].answer[0].point}`">
                  <b-form-input
                    id="input-1"
                    v-model.number="data.item.point"
                    type="number"
                    placeholder="Nilai"
                  ></b-form-input>
                </b-input-group>
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-danger
                    mr-1
                  "
                  v-else
                >
                  Siswa tidak mengerjakan soal
                </span>
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <!-- <span
                  @click="viewJawaban(data.item)"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-sm
                    mr-1
                  "
                  >Daftar Jawaban</span
                > -->
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_FROM_BANK_SOAL } from "@/core/services/store/soal.module";
import { GET_BANK_SOAL } from "@/core/services/store/banksoal.module";
import moment from "vue-moment";
import {
  CLEAR_UJIAN,
  GET_ONE_SOAL_ESSAY,
  STORE_PENILAIAN,
  STUDENT_RESULT_EXAM,
  GET_ONE_UJIAN,
} from "@/core/services/store/ujian.module";
// import loading from "vue-loading-overlay"
export default {
  name: "PenilaianSoalEssay",
  components: {
    // loading
  },
  data() {
    return {
      isLoading: false,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      modalBankSoal: false,
      bankSoal: [],
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "nilai",
          label: "Penilaian",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        // {
        //   key: 'topik',
        //   label: "Topik Soal",
        //   sortable: true
        // },
        // {
        //   key: 'progress',
        //   label: "Progress",
        //   sortable: true
        // },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
      kelasIdx: 0,
      currentSoal: {},
      currentPageBank: 1,
      totalRowsBank: 0,
      perPageBank: 10,
      filterBank: null,
      selectAll: false,
      fieldsBank: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        {
          key: "tipe",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(CLEAR_UJIAN);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Penilaian soal essay" }]);
    this.getData();
    this.getDetail();
    // this.getBankSoal();
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    ...mapGetters([
      "currentSoalEssay",
      "currentStudentResultExam",
      "currentUjian",
    ]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    idStudent() {
      return this.$route.params.idStudent;
    },
    // getOverallProgress(){
    //
    // },
    getTotalStudents() {
      var total = 0;
      this.currentSoalEssay.kurikulum.one_ajar.jadwal.forEach((item) => {
        total += item.kelas.det_kelas.length;
      });
      return total;
    },
  },
  methods: {
    // getProgress(item) {
    //   const attempted = item.siswa.filter(
    //     (siswa) => siswa.siswa.one_nilai_ujian.one_jawaban_siswa != null
    //   );
    //   const marked = attempted.filter(
    //     (jawab) => jawab.siswa.one_nilai_ujian.one_jawaban_siswa.nilai != null
    //   );
    //   return ((marked.length / attempted.length) * 100).toFixed(0);
    // },
    getFromBankSoal() {
      this.$bvModal
        .msgBoxConfirm(
          "Apakah anda yakin ingin ambil data soal dari bank soal?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.isLoading = true;
            var _data = this.bankSoal
              .filter((item) => {
                return item.checked;
              })
              .map((item) => {
                return item.id;
              });
            this.$store
              .dispatch(STUDENT_RESULT_EXAM, { id: this.idUjian, ids: _data })
              .then((response) => {
                this.getData();
                this.$bvToast.toast("Berhasil ambil dari bank soal", {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Export Rekap Nilai`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        });
    },
    onChangeAll() {
      this.bankSoal.forEach((item, index) => {
        this.$set(this.bankSoal[index], "checked", !this.selectAll);
      });
    },
    onChangeRow(e) {
      if (!e.target.checked) {
        this.selectAll = false;
      } else {
        var allSelected = this.bankSoal.every((val) => {
          return val.checked;
        });
        if (allSelected) {
          this.selectAll = true;
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredBank(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBank = filteredItems.length;
      this.currentPageBank = 1;
    },
    getData() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("question_type", "essay");

      this.$store
        .dispatch(STUDENT_RESULT_EXAM, {
          exam_id: this.idUjian,
          student_id: this.idStudent,
          params: params.toString(),
        })
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentStudentResultExam.answers.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat SOal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getDetail() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then((response) => {
          this.currentUjian = response.data;
          console.log();

          const tanggal_dibuka = moment(this.currentUjian.opening_time).format(
            "YYYY-MM-DD"
          );
          const tanggal_ditutup = moment(this.currentUjian.closing_time).format(
            "YYYY-MM-DD"
          );

          const start_time = moment(this.currentUjian.opening_time).format(
            "HH:mm"
          );

          const end_time = moment(this.currentUjian.closing_time).format(
            "HH:mm"
          );

          this.currentUjian.tanggal_dibuka = tanggal_dibuka;
          this.currentUjian.tanggal_ditutup = tanggal_ditutup;
          this.currentUjian.tanggal_dibuka_jam = start_time;
          this.currentUjian.tanggal_ditutup_jam = end_time;

          // const data = this.currentClassList.data.filter((item) =>
          //   this.currentUjian.class_id.includes(item._id)
          // );

          // this.currentAssignClass = data;

          this.selectedQuestionPack = this.currentUjian.question_pack_id._id;

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.kelasIdx = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    storeSoal() {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            var jawabSiswa = [];
            this.currentStudentResultExam.answers.forEach((item) => {
              const newItem = {
                point: item.point,
                question_id: item.question_id,
                student_id: this.idStudent,
              };
              jawabSiswa.push(newItem);
            });

            const _data = {
              _id: this.idUjian,
              body: jawabSiswa,
            };

            this.$store
              .dispatch(STORE_PENILAIAN, _data)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil simpan penilaian soal essay`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal simpan penilaian soal essay`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    getBankSoal() {
      const param = new URLSearchParams();
      param.append("id_kurikulum", this.idKurikulum);
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then((response) => {
          this.bankSoal = response.data;
          this.totalRowsBank = this.bankSoal.length;
          // this.isLoading = false
        })
        .catch(() => {
          // this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
